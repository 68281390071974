import jQuery from "jquery";
import select2 from "select2";
export default function selectEnhancer() {
    setTimeout( function() {
        jQuery(function($) {
            $('select').each(function () {
                const $selectElement = $(this);
                const placeholder = $selectElement.data('placeholder');
                const hasSearch = $selectElement.data('has-search');
                const hasMultiselect = $selectElement.data('has-multiselect'); 
                const isSkillAssessmentOption = $selectElement.attr('data-auto-width');
                const widthStyle = $selectElement.attr('data-auto-width');
                const parentLabel = $selectElement.closest('label');

                let cssClasses = isSkillAssessmentOption ? 'select2-skill-assessment' : '';
                    cssClasses += hasSearch ? ' select2-has-search' : '';

                const options = {
                    dropdownAutoWidth: true,
                    minimumResultsForSearch: hasSearch ? 0 : Infinity,
                    placeholder: {
                        id: '-1',
                        text: placeholder
                    },
                    multiple: hasMultiselect ? true : false,
                    // closeOnSelect: !hasMultiselect ? true : false,
                    dropdownCssClass: cssClasses,
                    width: isSkillAssessmentOption || widthStyle ? 'style' : 'resolve',
                };

                // if the select is in a <dialog>, set the <dialog> as the dropdown parent
                if (this.closest('dialog')) {
                    let dialogOptions = options;
                    dialogOptions.dropdownParent = $(this.closest('dialog'));

                    $selectElement.select2(dialogOptions);
                } else {
                    $selectElement.select2(options);
                }

                //if multiselect, add selected options count
                if (hasMultiselect) {
                    $selectElement.on('select2:close select2:select select2:unselect', function(event) {
                        let select = $(this);
                        select.next('span.select2').find('ul').html(function() {
                            let count = select.select2('data').length
                            if(count > 0) {
                                return "<li class='select2-count'>Valitud <span class='select2-count__counter'>" + count + "</span></li>"
                            }
                        })

                        if(event.type !== 'select2:close') {
                            select.select2('open');
                        }
                    })
                }

                //if select element has parent label, add label click event
                if (parentLabel.length) {
                    parentLabel.on('click', function(event) {
                        $selectElement.select2('open');
                    })
                }
            });
        });
    }, 10);
}
